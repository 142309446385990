const embedVideoUrl = (url: string) => {
  const parsedUrl = new URL(url);

  if ([
    "www.youtube.com",
    "youtube.com",
    "youtu.be",
  ].includes(parsedUrl.host)) {
    const id = parsedUrl.searchParams.get("v");

    return `//www.youtube.com/embed/${id}?rel=0`;
  }
  else if ([
    "www.vimeo.com",
    "vimeo.com",
  ].includes(parsedUrl.host)) {
    const id = parsedUrl.pathname.substring(1);

    return `//player.vimeo.com/video/${id}`;
  }
}

export default embedVideoUrl;
