import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import useScrollToTop from "~/hooks/useScrollToTop"
import decode from "~/functions/decode"
import SliderSlick from "react-slick";
import "./influencer.css"
import Box from "~/components/Box"
import Card from "~/components/Card"
import Picture from "~/components/Picture";
import embedVideoUrl from "~/functions/embedVideoUrl";
import { I18nContext, I18nLink } from "~/components/I18n";

export const query = graphql`query ($id: String!) {
  site {
    siteMetadata {
      title
      author
      siteUrl
    }
  }
  wpBrand(id: {eq: $id}) {
    id
    uri
    date
    title
    excerpt
    clipping {
      title
      caption
      altText
      sourceUrl
      srcSet
      sizes
      mimeType
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 900, height: 1200, quality: 92, layout: CONSTRAINED)
        }
      }
    }
    videography {
      embedUrl
    }
    featuredImage {
      node {
        title
        caption
        altText
        sourceUrl
        srcSet
        sizes
        mimeType
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 600, height: 600, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`

export default ({
  data: {
    wpBrand: {
      id, uri, title, excerpt, clipping, featuredImage, videography,
    },
    site: {
      siteMetadata: {
        title: siteTitle,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpBrand: {
    id: string
    uri: string
    date: string
    title: string
    excerpt: string
    clipping: {
      title: string
      caption: string
      altText: string
      sourceUrl: string
      srcSet: string
      sizes: string
      mimeType: string
      localFile: {
        publicURL: string
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
    videography: {
      embedUrl: string
    }
    featuredImage: {
      node: {
        sourceUrl: string
        srcSet: string
        sizes: string
        mimeType: string
        localFile: {
          publicURL: string
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      }
    }
  }
}>) => {
  useScrollToTop();

  const { locale } = useContext(I18nContext)

  const image = featuredImage?.node

  return <>
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: locale,
        dir: "ltr",
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      <title>{title}</title>
      <link rel="author" href="/humans.txt" />

      <meta name="description" content={excerpt} />
      <meta name="image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />

      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:description" content={excerpt} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={excerpt} />
      <meta name="twitter:image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />


    </Helmet>

    <header>
      <p className="is-size-1 is-uppercase is-font-family-serif">Brands</p>

      <nav className="is-uppercase has-half-margin-top">
        <I18nLink to="/brands"><b><i>Brands</i></b></I18nLink> // <Link to={uri}>{title}</Link>
      </nav>
    </header>

    <article id="site__influencer-article" className="is-post has-margin-bottom">
      <div id="site__influencer-gallery">{image && (
        featuredImage.node.localFile?.childImageSharp?.gatsbyImageData ? (
          <GatsbyImage
            image={featuredImage.node.localFile?.childImageSharp?.gatsbyImageData}
            alt={decode(title)}
          />
        ) : (
          <div className="is-responsive-1-1">
            <Picture src={image.sourceUrl} alt="" sources={[
              image
            ]} />
          </div>
        )
      )}</div>

      <header>
        <h1>{title}</h1>
      </header>

      <div className="article-content prose" dangerouslySetInnerHTML={{ __html: excerpt }} />

      {videography?.embedUrl && <section id="site__influencer-videography">
        <div className="is-responsive-16-9">
          <iframe
            src={embedVideoUrl(videography.embedUrl)}
            allowFullScreen
          />
        </div>
      </section>}

      {(clipping && clipping.length > 0) && <section id="site__influencer-campaigns">
        <h2>Recent Work</h2>
        <div id="site__influencer-gallery">
        <SliderSlick {...{
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }}>
          {clipping
          .map(({ title, altText, caption, sourceUrl, srcSet, sizes, mimeType, localFile }, index) => {
            const image = localFile?.childImageSharp?.gatsbyImageData

            return (
              <Box
                key={index}
                className="card-influencer-clipping__container"
                background={image ? (
                  <GatsbyImage image={image} alt="" />
                ) : (
                  <Picture src={sourceUrl} alt="" sources={[
                    {srcSet, sizes, mimeType}
                  ]} />
                )}
              >
                <Card
                  className={[
                    "is-align-items-bottom",
                    caption ? "has-caption" : "",
                  ].join(' ')}
                >
                  <article className="has-text-shadow">
                    <h4 className="has-no-margin-bottom">{decode(title || '')}</h4>
                    <p>{decode(altText || caption || '')}</p>
                  </article>
                </Card>
              </Box>
            )
          }
        )}
        </SliderSlick>
        </div>
      </section>}

    </article>
  </>;
}
